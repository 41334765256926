import { defineStore } from "pinia";

export const useAuthStore = defineStore('auth', () => {
  const hostAPI = useRuntimeConfig().public.hostAPI;
  const token = ref(useCookie('token', { maxAge: 86400 }));
  const redirectUrl = ref(useCookie('redirectUrl', { maxAge: 360 }));

  function setToken(tokenValue: any) {
    token.value = tokenValue;
  }

  function clear() {
    const authCookie = useCookie('token');
    authCookie.value = ''; // Remove completamente o cookie
    token.value = ''; // Atualiza imediatamente o estado do token
  }

  function setRedirectUrl(url: string) {
    redirectUrl.value = url;
  }

  async function checkToken() {
    try {
      if (!token.value) {
        return false;
      }

      const data: any = await $fetch(hostAPI + "user/auth/validate", {
        method: "post",
        headers: {
          Authorization: 'Bearer ' + token.value
        },
      });

      return data.status === 200;
    } catch (err) {
      return false;
    }
  }

  async function checkTokenAdminUser(): Promise<boolean> {
    try {
      if (!token.value) {
        return false;
      }

      const data: any = await $fetch(hostAPI + "user/auth/validate/admin", {
        method: "post",
        headers: {
          Authorization: 'Bearer ' + token.value
        },
      });

      return data.status === 200;
    } catch (err) {
      return false;
    }
  }

  return { setToken, checkToken, checkTokenAdminUser, clear, token, setRedirectUrl, redirectUrl };
});
